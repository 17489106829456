<template>
  <div>
    <ServicesTopBar />
    <Banner title="Browse Businesses by Category" />
    <section class="page_detail_section">
      <div class="container">

        <div class="row mt-5">
          <div
            v-for="(item, index) in paginatedData"
            :key="index"
            class="col-6 col-sm-4 col-md-2 mb-2"
            @click="selectCategory(item.id)"
          >
            <div class="category-item cat-1">
              <feather-icon
                icon="GridIcon"
                size="20"
              />
              <span class="text-center">{{ item.name }}</span>
            </div>
          </div>
          <div class="col-md-12 mt-4 mb-4">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="lg"
              class="my-0"
              @change="onPageChanged"
            />
          </div>
        </div>
      </div>
    </section>
    <SiteFooter />
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
import Banner from '../components/page/Banner.vue'
import ServicesTopBar from '../components/services/ServicesTopBar.vue'
import SiteFooter from '../components/site/SiteFooter.vue'

export default {
  components: {
    ServicesTopBar, SiteFooter, Banner, BPagination,
  },
  data() {
    return {
      perPage: 12,
      currentPage: 1,
      pageOptions: [3, 5, 10],
    }
  },
  computed: {
    categories() {
      return this.$store.state.category.categories
    },
    profile_param: {
      get() {
        return this.$store.state.businessProfile.param
      },
      set(value) {
        return value
      },
    },
    paginatedData: {
      get() {
        const start = (this.currentPage - 1) * this.perPage
        const end = start + this.perPage
        return this.categories.slice(start, end)
      },
      set(value) {
        return value
      },
    },
    totalRows() {
      return this.categories.length
    },
  },
  mounted() {
  },
  methods: {
    getImage(image) {
      return `background: url(${image})`
    },
    selectCategory(item) {
      this.profile_param.categories = [item]
      this.$router.push({ name: 'services' }).catch(() => {})
    },
    onPageChanged(page) {
      this.currentPage = page
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      this.paginatedData = this.categories.slice(start, end)
    },
  },
}
</script>

<style scoped>

</style>
